import { Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { Button, Link, Picture, TextLink } from '@troon/ui';
import { twJoin } from '@troon/tailwind-preset/merge';
import { useAction } from '@solidjs/router';
import { IconStar } from '@troon/icons/star';
import { IconStarFilled } from '@troon/icons/star-filled';
import { gql, mutationAction, useMutation } from '../../graphql';
import { useUser } from '../../providers/user';
import { createFragment } from '../../graphql/create-fragment';
import type { FragmentType } from '../../graphql';

type Props = {
	facility: FragmentType<typeof FacilityCardFragment>;
	level?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	loading?: 'eager' | 'lazy';
	linkToTeeTimes?: boolean;
	disableFavorites?: boolean;
	phone?: string;
};

export function FacilityCard(props: Props) {
	const user = useUser();
	const favorite = useAction(useMutation(handleFavorite));
	const facility = createFragment(FacilityCardFragment, props, 'facility');

	return (
		<article class="group relative">
			{facility.metadata?.hero?.url ? (
				<div class="mb-2 aspect-video overflow-hidden rounded">
					<Picture
						src={facility.metadata?.hero?.url ?? ''}
						width={500}
						height={281}
						sizes="(min-width: 1024px) 33vw, (min-width: 768px) 50vw, 95vw"
						alt={`Photo from the golf course at ${facility.name}`}
						class="size-full rounded bg-neutral-500 object-cover group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
						loading={props.loading}
					/>
				</div>
			) : (
				<div class="mb-2 aspect-video w-full rounded bg-neutral-500" />
			)}
			<Dynamic component={props.level ?? 'h2'} class="text-base font-semibold">
				<Link
					href={`/course/${facility.slug}${props.linkToTeeTimes ? '/reserve-tee-time' : ''}`}
					class="transition-colors duration-200 after:absolute after:inset-0 after:z-0 group-hover:text-brand-500"
					preload
				>
					{facility.name}
				</Link>
			</Dynamic>
			<Show when={facility.metadata?.address?.city || facility.metadata?.address?.state}>
				<p class="flex flex-row gap-2 text-sm font-medium text-neutral-800">
					<span>
						<Show when={facility.metadata?.address?.city}>{facility.metadata?.address?.city}, </Show>
						{facility.metadata?.address?.state}
					</span>
					<Show when={props.phone}>
						{(phone) => (
							<>
								•
								<span class="relative">
									{' '}
									Ph:{' '}
									<TextLink class="z-10 after:absolute after:-inset-2" href={`tel:${phone()}`}>
										{phone()}
									</TextLink>
								</span>
							</>
						)}
					</Show>
				</p>
			</Show>
			<Show when={!props.disableFavorites && user()}>
				<Button
					appearance="transparent-current"
					class="absolute right-2 top-2 text-black"
					onClick={() => {
						const data = new FormData();
						data.set('facilityId', facility.id);
						data.set('isFavorite', facility.isFavorite ? 'false' : 'true');
						favorite(data);
					}}
				>
					<IconStarFilled
						class={twJoin('absolute z-10 text-xl', facility.isFavorite ? 'text-brand' : 'text-black/30')}
					/>
					<IconStar class="relative z-20 text-xl text-white" />
					<span class="sr-only">Favorite</span>
				</Button>
			</Show>
		</article>
	);
}

const FacilityCardFragment = gql(`fragment FacilityCard on Facility {
	id
	slug
	name
	isFavorite
	metadata {
		address { city, state }
		hero { url }
	}
}`);

const toggleFavorite = gql(`mutation toggleFavoriteFacility($facilityId: String!, $isFavorite: Boolean!) {
	toggleFavoriteFacility(facilityId: $facilityId, isFavorite: $isFavorite) {
		name
		isFavorite
	}
}`);

const handleFavorite = mutationAction(toggleFavorite, {
	transform: (data) => ({
		facilityId: data.get('facilityId') as string,
		isFavorite: data.get('isFavorite') === 'true',
	}),
	toast: (data) =>
		data?.toggleFavoriteFacility.isFavorite
			? `Added ${data?.toggleFavoriteFacility.name} as a favorite`
			: `Removed ${data?.toggleFavoriteFacility.name} as a favorite.`,
	revalidates: ['facility', 'home'],
});
